@font-face {
  font-family: "rr";
  src: url("assets/fonts/Euclid-Circular-Regular.ttf");
}
@import "~css-star-rating/css/star-rating.min.css";

@font-face {
  font-family: "rl";
  src: url("assets/fonts/Euclid-Circular-Light.ttf");
}

@font-face {
  font-family: "rm";
  src: url("assets/fonts/Euclid-Circular-Medium.ttf");
}

@font-face {
  font-family: "rb";
  src: url("assets/fonts/Euclid-Circular-Bold.ttf");
}

@font-face {
  font-family: "rbl";
  src: url("assets/fonts/Raleway-Black.ttf");
}

.use-pointer {
  cursor: pointer;
}

.workcr-card {
  .rating {
    justify-content: start !important;
  }
}

* {
  font-family: rr;
}
button {
  border: 0 !important;
}
.star-review {
  .star {
    width: 14px !important;
  }
}
.badge-assigned {
  background-color: #007bff;
  color: #fff;
}
.badge-warning {
  color: #fff;
}
.btn-primary:active {
  background-color: #f92044 !important;
}
.btn-primary:hover {
  color: #fff;
  border: 0;
  background-color: #000;
}
.empty-card {
  padding: 10% 5%;
  img {
    width: 150px;
    margin-bottom: 2%;
  }
  h5 {
    font-family: "rm";
    color: #999;
  }
}

@media only screen and (max-width: 768px) {
  .apply-form {
    padding: 0% 0 10% !important;
    .apply-over {
      img {
        width: 435px !important;
      }
    }
  }
  .hmjp-smain {
    width: 50%;
  }
  .apslast {
    margin-bottom: 0 !important;
  }
  .contact-data,
  .apply-sub {
    padding-bottom: 0 !important;
  }
  .abm-cocard {
    h6 {
      font-size: 1.5rem !important;
    }
    span {
      font-size: 0.9rem !important;
    }
  }
  .abt-text {
    margin-top: 25%;
  }
  .abmb-inner {
    width: 100% !important;
    margin: 0 auto 6%;
    h5 {
      font-size: 1.5rem !important;
    }
  }
  .privacy-subheader {
    .nav-link {
      padding: 1rem 0.5rem !important;
    }
  }
  .privacy-grand {
    h3 {
      font-size: 1.7rem !important;
    }
    p {
      font-size: 0.9rem !important;
    }
  }
  .books-bot {
    margin-bottom: 5%;
  }
  .hc-main {
    justify-content: flex-start !important;
  }
  .bks-main {
    margin-bottom: 5%;
  }
  .sc-adcmain {
    display: flex;
    justify-content: flex-start;
  }
  .loginb-main {
    width: 70% !important;
  }
  .hmj-search .hmjs-inner {
    width: 95% !important;
  }
  .apply-sub {
    padding: 5% 0;
  }
  .apmi-card {
    margin-bottom: 5%;
  }
  .apply-sub .apsub-left {
    padding: 10% 5% !important;
  }
  .apm-bgheader {
    margin-top: 30% !important;
  }
  .apmi-header p {
    margin: 12px 10% !important;
  }
  .jumb-bg .jumb-bginner h4 {
    font-size: 1.7rem !important;
  }
  .apsub-right {
    img {
      height: 330px !important;
    }
  }
  .contact-main {
    .contact-left {
      h5 {
        margin-bottom: 0% !important;
      }
      h5::after {
        margin: 2% 0 !important;
      }
      p {
        margin-bottom: 2% !important;
      }
    }
    .contact-left-bot {
      .d-flex {
        margin-bottom: 2% !important;
        .clb-icon {
          margin-right: 2% !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .apply-form {
    padding: 5% 0 0 !important;
    .apply-over {
      position: relative !important;
      img {
        width: 95% !important;
      }
    }
  }
  .apsub-right {
    text-align: center !important;
  }
  .hmjp-header {
    h5 {
      font-size: 1.2rem !important;
    }
    .btn {
      width: 120px;
      font-size: 0.9rem;
      height: 32px;
    }
  }
  .aps-download {
    margin-top: 0 !important;
  }
  .hmjps-cinner {
    display: table;
    height: 47px;
    h6 {
      display: table-cell;
      vertical-align: middle;
    }
  }
  .abm-cocard {
    margin-bottom: 10% !important;
    h6 {
      font-size: 1.3rem !important;
    }
  }
  .abm-col {
    width: 50% !important;
  }
  .hmheader-top {
    h5 {
      font-size: 1.6rem !important;
    }
    p {
      font-size: 1rem !important;
    }
  }
  .abmb-crimg {
    height: 200px !important;
  }
  .abmb-clinner {
    h5 {
      font-size: 1.4rem !important;
    }
    p {
      font-size: 1rem !important;
      padding-right: 0 !important;
      margin-bottom: 5% !important;
    }
  }
  .abt-text {
    margin-top: 7% !important;
  }
  .abmb-inner h5 {
    font-size: 1.3rem !important;
  }
  .navbar-brand {
    img {
      height: 30px !important;
    }
  }
  .privacy-desc {
    p {
      font-size: 0.9rem !important;
    }
  }
  .privacy-subheader {
    .nav-link {
      font-size: 0.9rem !important;
    }
  }
  .privacy-grand {
    h3 {
      font-size: 1.3rem !important;
    }
    p {
      font-size: 0.9rem !important;
    }
  }
  .serbook-header {
    h5 {
      font-size: 1.3rem !important;
    }
  }
  .empty-card {
    img {
      width: 100px !important;
    }
    h5 {
      font-size: 1rem;
    }
  }
  .books-top {
    h4 {
      font-size: 1.2rem !important;
    }
  }
  .accset-card {
    .alert {
      font-size: 0.9rem !important;
    }
    input {
      font-size: 0.9rem !important;
    }
    .btn {
      font-size: 0.9rem !important;
    }
    .accheader {
      h5 {
        font-size: 1rem !important;
      }
    }
  }
  .mfm-logo {
    margin-bottom: 4% !important;
  }
  .loginmain .loginbot .lbm-header h5 {
    font-size: 1.3rem !important;
  }
  .main-footer {
    ul {
      margin-bottom: 4%;
    }
    .mf-header h5 {
      font-size: 1.1rem !important;
    }
  }
  .mf-left {
    margin-bottom: 4%;
  }
  .loginb-main {
    width: 90% !important;
  }
  .hmjps-cinner {
    h6 {
      font-size: 0.85rem !important;
    }
  }
  .ser-catmain {
    padding: 3% 2px 0 !important;
  }
  .se-lm {
    display: none;
  }
  .sel-mr {
    width: 50%;
  }
  .hmj-search .hmjs-inner h4 {
    font-size: 1.3rem !important;
  }
  .contact-main .contact-left-bot .d-flex {
    margin-bottom: 3% !important;
  }

  .apm-bgheader {
    margin-top: 5% !important;
    h4 {
      font-size: 1.7rem !important;
    }
    p {
      font-size: 1rem !important;
      margin-bottom: 3% !important;
    }
    button {
      margin-bottom: 5%;
    }
  }
  .apm-form {
    label {
      font-size: 0.9rem !important;
    }
    padding: 6% !important;
    margin-bottom: 4% !important;
    h5 {
      font-size: 1.3rem !important;
    }
  }
}

@media only screen and (max-width: 320px) {
  .contact-main .contact-left-bot .d-flex {
    margin-bottom: 5% !important;
  }
  .contact-main .contact-left p {
    margin-bottom: 6% !important;
  }
}
.mainBookModal {
  .modal-title {
    font-family: rm;
  }
  .bookmodal {
    .bookinfo {
      padding: 3%;
      border-radius: 5px;
      background-color: #f3f3f3;
      margin-bottom: 2%;
      h5 {
        color: #000;
        font-family: rm;
      }
    }
    .form-group {
      margin-bottom: 0.5rem;
      label {
        font-size: 0.9rem;
      }
      input,
      textarea {
        color: #000;
        font-family: rm;
        border: 1px solid #e6e6e6;
        resize: none;
      }
      textarea:focus {
        box-shadow: 0 0 0 0;
      }
      textarea::placeholder {
        color: #999;
        font-family: rr;
      }
    }
  }
  .modal-footer {
    .btn {
      font-family: rm;
      border: 0;
    }
  }
}
.sctinner-main {
  .scti-top {
    margin: 3% 0;

    .scti-tinner {
      width: 100%;
      padding: 3%;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.16);
      border-radius: 20px;
      //  border: 1px solid #f5f5f5;

      .sct-info {
        margin-top: 2%;

        span {
          font-size: 0.8rem;
        }

        h5 {
          font-size: 1.5rem;
          font-family: rm;
          margin: 0.5% 0 !important;
        }

        p {
          font-size: 0.95rem;
        }
      }

      .sct-img {
        margin-right: 2%;

        img {
          width: 150px;
          height: 150px;
          object-fit: cover;
          border-radius: 20px;
        }
      }
    }
  }

  .scti-bot {
    .nav-link {
      font-family: rm;
      color: #333;
    }

    .nav-link.active {
      color: var(--primary);
    }

    .scti-ncard {
      width: 100%;
      height: auto;
      padding: 8% 4%;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      margin-bottom: 5%;

      h6 {
        font-family: rm;
        font-size: 0.9rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .scti-recard {
      width: 100%;
      height: auto;
      border-radius: 10px;
      padding: 5%;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
      margin-bottom: 5%;

      h6 {
        font-size: 0.85rem;
        font-family: rm;
        // margin-bottom: 2% !important;
      }

      span {
        font-size: 0.75rem;
      }

      .badge {
        font-weight: normal;
      }

      p {
        font-size: 0.85rem;
        margin-bottom: 4% !important;
      }

      .scti-redate {
        font-size: 0.8rem;
        color: #999;
      }
    }
  }

  .sc-adcmain {
    margin-top: 10%;

    .sc-adcard {
      margin-bottom: 10%;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
      border-radius: 20px;

      img {
        width: 100%;
        border-radius: 20px;
      }
    }
  }
}

.loginb-main {
  padding: 5%;
  background-color: #fff;
}

.loginb-main {
  width: 45%;
}

.apply-simg {
  img {
    width: 135px;
    height: 40px;
  }
}

.apmi-header {
  text-align: center;
  margin-bottom: 5%;

  h5 {
    font-size: 1.5rem;
    text-transform: capitalize;
    font-family: rm;
    margin-bottom: 0.5% !important;
  }

  p {
    margin: 0 23%;
  }
}

.apply-sub {
  .container-fluid {
    padding: 0;
  }

  // background-color: #f9fafb;

  .apsub-right {
    .apr-img {
      height: 450px;
    }
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .apsub-left {
    padding: 10% 5% 10% 13%;

    h5 {
      text-transform: capitalize;
      font-size: 1.4rem;
      font-family: rm;
      margin-bottom: 2% !important;
    }

    p {
      font-size: 0.9rem;
      margin-bottom: 3% !important;
    }

    li {
      background: transparent;
      border: 0;
      padding: 2% 0;
    }
  }
}

.booking-card {
  width: 100%;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 5%;
  border-radius: 10px;
  margin-bottom: 5%;

  h5 {
    font-family: rr;
    font-size: 0.9rem;
    margin-bottom: 1.5% !important;
  }

  h6 {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 1% !important;
  }

  p {
    font-size: 0.9rem;
    color: #999;
    margin-bottom: 2% !important;
  }

  .badge {
    display: table;
    padding: 0.35em 0.8em;
  }

  span.text-uppercase {
    font-size: 1rem;
    font-family: rm;
  }

  span {
    font-size: 0.8rem;
  }
}

.books-main {
  margin: 1% 0 5%;

  .books-top {
    padding: 2% 0;

    h4 {
      font-size: 1.7rem;
      font-family: rm;
    }

    .btn {
      font-family: rm;
    }
  }
}

input:focus,
.btn:focus {
  box-shadow: 0 0 0 0 !important;
}

.logintop {
  border-bottom: 1px solid #f5f5f5;

  .navbar-nav {
    .nav-link {
      color: #000;
    }
  }

  .hm-loginbtn {
    font-family: rm;
    font-size: 0.9rem;
    padding: 0.175rem 1rem;
    border-radius: 50px;
    margin-left: 5%;
    border: 0;
  }
}

.loginmain {
  .loginbot {
    padding: 5% 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .loginb-main {
      margin: 0 auto;
    }

    .lbm-header {
      h5 {
        font-size: 1.5rem;
        font-family: rm;
        margin-bottom: 2% !important;
      }

      p {
        color: rgba($color: #000000, $alpha: 0.39);
        margin-bottom: 3% !important;
      }
    }

    .lbm-form {
      input:focus {
        border: 1px solid var(--primary);
      }

      input {
        border: 1px solid #f5f5f5;
        padding: 0.75rem 0.95rem;
        height: auto;
        color: #000;
        border-radius: 0;
        font-family: rm;
      }

      button {
        padding: 0.75rem 0.75rem;
        font-family: rm;
        border-radius: 0;
      }

      button:hover {
        background-color: var(--primary);
        border-color: var(--primary);
      }

      button:focus {
        box-shadow: 0 0 0 0 !important;
        border-color: var(--primary);
      }
    }

    .lbm-options {
      a {
        color: rgba(0, 0, 0, 0.3);
        font-family: rm;
        text-decoration: underline;
      }

      .btn {
        padding: 0.5rem 0.75rem;
        font-family: rm;
        border-radius: 0;
      }

      .btn-gmail {
        color: #fff;
        background-color: #dd4b39;
      }

      .btn-fb {
        color: #fff;
        background-color: #3b5998;
      }
    }
  }
}

.jumb-bg {
  width: 100%;
  height: 25vh;
  background-position: center;
  background-size: cover;
  display: table;

  .jumb-bginner {
    display: table-cell;
    vertical-align: middle;

    h4 {
      color: #fff;
      font-size: 3rem;
      font-family: rm;
    }
  }
}

.hmheader-top {
  margin-bottom: 4%;

  h5 {
    font-size: 2rem;
    font-family: rm;
  }

  h5::after {
    display: block;
    width: 5%;
    height: 3px;
    background-color: var(--primary);
    content: "";
    margin: 1% auto;
  }

  p {
    // padding: 0 20%;
    font-family: rl;
    font-size: 1.1rem;
    margin: 1% 0;
  }
}

.hms-bot {
  .hms-card {
    background-color: #fff;
    padding: 15% 5%;
    border-radius: 30px;
    margin-bottom: 10%;

    img {
      width: 60px;
      margin-bottom: 5%;
    }

    h6 {
      font-family: rm;
      font-size: 1.1rem;
      margin-bottom: 2% !important;
    }

    p {
      font-size: 0.9rem;
      font-family: rl;
    }
  }
}

.main-footer {
  padding: 5% 0 3%;

  .mf-left {
    .mfm-logo {
      width: 200px;
    }
  }

  .mf-header {
    h5 {
      font-size: 1.3rem;
      font-family: rm;
    }

    h5::after {
      display: block;
      width: 30%;
      height: 3px;
      background-color: var(--primary);
      content: "";
      margin: 3% 0;
    }
  }

  li {
    border: 0;
    padding: 0.3rem 0;
    background-color: transparent;

    a {
      color: #000;
    }

    a:hover {
      color: var(--primary);
      text-decoration: none;
    }
  }

  .btn-group {
    margin-top: 4%;

    button {
      border-radius: 50% !important;
      padding: 0.45rem 0.75rem;
      border: 0;
      background-color: #444;
      color: #999;
      margin-right: 6%;
    }
  }

  .text-center {
    border-top: 1px solid #292929;
    padding-top: 3%;
    margin-top: 3%;

    h6 {
      font-size: 0.85rem;
    }
  }
}

.features-bot {
  .fbot-main {
    .fbot-card:hover {
      transform: translate3d(0, -6px, 0);
    }

    .fbot-card::before {
      content: "";
      top: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 9;
      background: linear-gradient(
        to top,
        rgba(35, 35, 37, 0.9) 0%,
        rgba(35, 35, 37, 0.75) 35%,
        rgba(22, 22, 23, 0) 60%,
        rgba(0, 0, 0, 0) 100%
      );
      border-radius: 30px;
      opacity: 1;
    }

    .fbot-card {
      transition: transform 0.3s;
      position: relative;
      height: 500px;
      margin-bottom: 10%;

      img {
        width: 100%;
        height: 500px;
        object-fit: cover;
        border-radius: 30px;
      }

      .fbot-overtop {
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        padding: 8%;

        span {
          font-size: 1rem;
          font-family: rm;
        }
      }

      .fbot-overbot {
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 0;
        color: #fff;
        z-index: 99;
        padding: 8%;

        h6 {
          font-family: rm;
          font-size: 1.2rem;
          margin-bottom: 5% !important;
        }

        p {
          font-family: rl;
        }
      }
    }
  }
}

select:focus {
  box-shadow: 0 0 0 0 !important;
}

.nav-item {
  position: relative;

  .active-pulse {
    width: 10px;
    height: 10px;
    background-color: var(--primary);
    border-radius: 50%;
    position: absolute;
    top: 15px;
    right: -5px;
    animation: shadow-pulse 1s infinite;
  }
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(249, 25, 66, 0.5);
  }

  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}

.allevents-main {
  .alle-top {
    background-color: #fafafa;
    padding: 2%;

    .alle-topinner {
      h5 {
        font-family: rm;
        font-size: 1.6rem;
        margin-bottom: 2% !important;
      }

      width: 70%;
      margin: 0 auto;

      select {
        padding: 0.75rem 1.75rem 0.75rem 0.75rem;
        border: 1px solid #f5f5f5;
        border-radius: 50px;
        font-family: rm;
        height: auto;
      }

      button {
        padding: 0.75rem 0.75rem;
        font-family: rm;
        border-radius: 50px;
      }
    }
  }

  .alle-bot {
    padding: 5% 0;

    .fbot-card {
      .fbot-overbot {
        h6 {
          font-family: rm;
          font-size: 1.7rem;
          margin-bottom: 3% !important;
        }

        p {
          font-family: rm;
          color: #ddd;
        }

        .allev-loc {
          margin-bottom: 2% !important;
        }

        span {
          color: #000;
          background-color: #16dd00;
          padding: 3%;
          font-family: rm;
          font-size: 1rem;
        }
      }
    }
  }
}

.ser-catmain {
  width: 100%;
  padding: 3% 30px 0;

  .owl-stage {
    margin-bottom: 1% !important;
  }

  .owl-carousel {
    .owl-dots {
      display: none;
    }

    .owl-nav {
      button {
        // width: 100%;
        top: 20%;
        position: absolute !important;
        background-color: #fff;
        padding: 0.2% 1% 0.4% !important;
        font-size: 1.5rem;
      }
      .owl-prev {
        left: 0;
      }
      .owl-next {
        right: 0;
      }

      button:hover {
        background-color: #fff;
        color: var(--primary);
      }
    }
  }

  .sercat-card {
    box-shadow: 0 5px 11px rgba(0, 0, 0, 0.05);
    border-radius: 10px;

    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      height: 140px;
      object-fit: cover;
    }

    h6 {
      font-family: rm;
      padding: 3% 0;
      font-size: 0.85rem;
    }
  }

  .item {
    margin-bottom: 8px;
  }
}

.hmj-search {
  width: 100%;
  background-position: center;
  padding: 5%;

  .hmjs-inner {
    width: 55%;
    height: auto;
    margin: 0 auto;
    background: #fff;
    padding: 4% 5%;
    border-radius: 10px;

    h4 {
      text-align: center;
      margin-bottom: 2% !important;
      font-size: 2rem;
      font-family: rm;
    }

    .selj-search {
      margin-top: 3%;

      select {
        height: auto;
        padding: 0.75rem 1.75rem 0.75rem 0.75rem;
        border: 0;
        border: 1px solid #f5f5f5;
        border-radius: 0;
        color: #000;
      }

      select::placeholder {
        color: #555;
      }

      button {
        border: 0;
        border: 1px solid #f92044;
        border-radius: 0;
        font-family: rm;
        padding: 0.75rem 0.75rem;
      }
    }
  }
}

.hmjps-card {
  .hmjps-cinner {
    padding: 4%;
  }

  .badge {
    color: #999;
  }

  margin-bottom: 10%;
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.16);

  img {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 20vh;
    object-fit: cover;
  }
}

.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #ffffff;
}

.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51a351;
}

.toast-error {
  background-color: #bd362f;
}

.toast-info {
  background-color: #2f96b4;
}

.toast-warning {
  background-color: #f89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
